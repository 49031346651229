<template>
    <div class="upvotes-wrapper" @click="!feature.has_upvoted ? $emit('onUpvote', feature) : $toast.info('Voto já computado')">
        <ArrowUp class="arrow-up" :class="{ active: !feature.has_upvoted }" />
        <div>{{ parseValue(feature.upvotes) }}</div>
    </div>
</template>
<script>
export default {
    components: {
        ArrowUp: () => import('@/assets/icons/chevron-up.svg'),
    },
    props: {
        feature: {
            type: Object,
            required: true,
        },
    },
    methods: {
        parseValue(value) {
            if (!value) return 0
            return value <= 999 ? value : '+999'
        },
    },
}
</script>
<style lang="scss" scoped>
.arrow-up {
    width: 30px;
    margin-bottom: -10px;
    stroke: var(--type-placeholder);

    &.active {
        stroke: var(--blue-500);
    }
}
.upvotes-wrapper {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 0 8px;
    color: #424242;
    font-weight: 700;
}
</style>